@media (max-width: 768px) {
    .ts-intro {
        margin-top: -110px;
    }
}

.video-container {
    position: relative;
    padding-bottom: 56.25%; /* Relación de aspecto 16:9 */
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Asegura que el video cubra todo el contenedor */
}