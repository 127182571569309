.slider-overlay:after{
    background: rgba(0, 0, 0, 0.5);
}

.slider-overlay{
    z-index: 2;
}

.slider-content .slider-desc {
    font-size: 22px;
    margin-bottom: 25px;
    line-height: 25px;
}
