.box-skew-left:after{
    background: #021025;
}

 table {
    color: black !important;
    border-radius: 10px; /* Ajusta este valor según tu preferencia */
    border-spacing: 0;
    border-collapse: collapse;
    width: 90%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* Agrega una sombra alrededor de la tabla */
    border: 0;
    margin: 10px;
 }

 td, th {
    padding: 10px;
 }

 td:first-child, th:first-child {
    border-top-left-radius: 10px;
 }

 td:last-child, th:last-child {
    border-top-right-radius: 10px;
 }

 tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
 }

 tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
 }

 .slider-items-tips{
    height: 100%;
 }